import { DashboardDataResponse } from './../interfaces/dashboard-data-response.interface';
import { map } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { environment } from '../../environments/environment';
import { DashboardDataItem } from '../models/dashboard-data-item.model';

import * as moment from 'moment';

@Injectable({
  providedIn: 'root'
})
export class DashboardService {

  constructor(
    private http: HttpClient
  ) { }

  getData(fromDate: moment.Moment, toDate: moment.Moment) {
    const queryStringVars = [];
    let queryString = '';

    if (fromDate !== null) {
        queryStringVars.push('from_date=' + fromDate.format('YYYY-MM-DD'));
    }
    if (toDate !== null) {
        queryStringVars.push('to_date=' + toDate.format('YYYY-MM-DD'));
    }

    queryString = queryStringVars.join('&');

    return this.http.get(`${environment.apiUrl}/dashboard/data?${queryString}`).pipe(
      map((response: DashboardDataResponse) => {
        return response.data.map(data => new DashboardDataItem().hydrateWith(data));
      })
    );
  }
}
