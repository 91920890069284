import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpErrorResponse
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {

  constructor() {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      catchError(errorResponse => {
        let returnErrorResponse: HttpErrorResponse;

        if (errorResponse instanceof HttpErrorResponse) {
          returnErrorResponse = new HttpErrorResponse({
            error: typeof errorResponse.error.msg !== undefined ? errorResponse.error.msg : 'Ha ocurrido un error',
            headers: errorResponse.headers,
            status: errorResponse.status,
            statusText: errorResponse.statusText,
            url: errorResponse.url
          });
        } else {
          returnErrorResponse = errorResponse;
        }

        return throwError(returnErrorResponse);
      })
    );
  }
}
