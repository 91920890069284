import { Hydratable } from './../interfaces/hydratable.interface';

export class Shipping implements Hydratable {
  id: number;
  shipmentId: string;
  status: string;
  tripId: string;
  login: string;
  channel: string;
  receiverNickname: string;
  senderNickname: string;
  orderId: string;

  hydrateWith(data: any): this {
    return Object.assign(this, data);
  }
}
