import { ErrorInterceptor } from './interceptors/error.interceptor';
import { BrowserModule } from '@angular/platform-browser';
import { NgModule, LOCALE_ID } from '@angular/core';

import { registerLocaleData } from '@angular/common';
import localeEs from '@angular/common/locales/es';

registerLocaleData(localeEs);

import { FormsModule } from '@angular/forms';

import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';

import { ChartsModule } from 'ng2-charts';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { DashboardComponent } from './components/dashboard/dashboard.component';
import { NavbarComponent } from './components/shared/navbar/navbar.component';
import { LoginComponent } from './components/login/login.component';
import { AuthInterceptor } from './interceptors/auth.interceptor';
import { UserDetailComponent } from './components/user-detail/user-detail.component';
import { LoadingComponent } from './components/shared/loading/loading.component';
import { RegisterComponent } from './components/register/register.component';
import { ActivateComponent } from './components/activate/activate.component';
import { DashboardChartComponent } from './components/shared/dashboard-chart/dashboard-chart.component';
import { ManifestListComponent } from './components/shared/manifest-list/manifest-list.component';
import { PaginatorComponent } from './components/shared/paginator/paginator.component';
import { ManifestComponent } from './components/manifest/manifest.component';
import { FooterComponent } from './components/shared/footer/footer.component';
import { TawktoComponent } from './components/shared/tawkto/tawkto.component';
import { DatePeriodPipe } from './pipes/date-period.pipe';
import { ResetPasswordComponent } from './components/reset-password/reset-password.component';
import { RecoverComponent } from './components/recover/recover.component';

@NgModule({
  declarations: [
    AppComponent,
    DashboardComponent,
    NavbarComponent,
    LoginComponent,
    UserDetailComponent,
    LoadingComponent,
    RegisterComponent,
    ActivateComponent,
    DashboardChartComponent,
    ManifestListComponent,
    PaginatorComponent,
    ManifestComponent,
    FooterComponent,
    TawktoComponent,
    DatePeriodPipe,
    ResetPasswordComponent,
    RecoverComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    HttpClientModule,
    ChartsModule
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ErrorInterceptor,
      multi: true
    },
    {
      provide: LOCALE_ID,
      useValue: 'es'
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
