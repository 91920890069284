import { RefreshTokenResponse } from './../interfaces/refresh-token-response.interface';
import { LogoutResponse } from './../interfaces/logout-response.iterface';
import { User } from './../models/user.model';
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import {environment} from '../../environments/environment';

import { map } from 'rxjs/operators';
import { LoginResponse } from '../interfaces/login-response.interface';
import { throwError } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  private accessToken: string;

  private refreshToken: string;

  private user: User;

  constructor(private http: HttpClient) {
    this.readStorageData();
  }

  login(user: User) {
    return this.http.post(`${environment.apiUrl}/auth/login`, user)
      .pipe(
        map((response: LoginResponse) => {
          this.saveDataToStorage(response);
          this.readStorageData();

          return response;
        })
      );
  }

  logout() {
    const json = {
      user_id: this.getUser().id,
      refresh_token: this.getRefreshToken()
    };
    const headers = new HttpHeaders({
      'Content-Type': 'application/json'
    });

    return this.http.post(`${environment.apiUrl}/auth/logout`, json, {headers}).pipe(
      map((response: LogoutResponse) => {
        this.clearStorageData();

        return response;
      })
    );
  }

  register(email: string) {
    const json = {email};
    const headers = new HttpHeaders({
      'Content-Type': 'application/json'
    });

    return this.http.post(`${environment.apiUrl}/auth/register`, json, {headers});
  }

  activate(user: User, activationToken: string) {
    const json = {
      user_id: user.id,
      password: user.password,
      activation_token: activationToken
    };
    const headers = new HttpHeaders({
      'Content-Type': 'application/json'
    });

    return this.http.post(`${environment.apiUrl}/auth/activate`, json, {headers}).pipe(
      map((response: LoginResponse) => {
        this.saveDataToStorage(response);
        this.readStorageData();

        return response;
      })
    );
  }

  refreshAccessToken() {
    if (!this.isLoggedIn()) {
        return throwError('El usuario no se encuentra logueado');
    }

    const json = {
        user_id: this.getUser().id,
        refresh_token: this.getRefreshToken()
    };
    const headers = new HttpHeaders({
        'Content-Type': 'application/json'
    });

    return this.http.post(`${environment.apiUrl}/auth/refresh`, json, {headers}).pipe(
      map((response: RefreshTokenResponse) => {
        this.saveAccessToken(response.access_token);

        return response;
      })
    );
  }

  changePassword(currentPassword: string, newPassword: string) {
    const json = {
      currentPassword,
      newPassword
    };
    const headers = new HttpHeaders({
      'Content-Type': 'application/json'
    });

    return this.http.post(`${environment.apiUrl}/auth/change-password`, json, {headers});
  }

  recover(email: string) {
    const json = {email};
    const headers = new HttpHeaders({
      'Content-Type': 'application/json'
    });

    return this.http.post(`${environment.apiUrl}/auth/recover`, json, {headers});
  }

  resetPassword(user: User, activationToken: string) {
    const json = {
      user_id: user.id,
      password: user.password,
      reset_token: activationToken
    };
    const headers = new HttpHeaders({
      'Content-Type': 'application/json'
    });

    return this.http.post(`${environment.apiUrl}/auth/reset-password`, json, {headers}).pipe(
      map((response: LoginResponse) => {
        this.saveDataToStorage(response);
        this.readStorageData();

        return response;
      })
    );
  }

  getUser(): User {
    return this.user;
  }

  getAccessToken(): string {
    return this.accessToken;
  }

  getRefreshToken(): string {
    return this.refreshToken;
  }

  clearStorageData() {
    localStorage.removeItem('user_identity');
    localStorage.removeItem('access_token');
    localStorage.removeItem('refresh_token');

    this.user = null;
    this.accessToken = null;
    this.refreshToken = null;
  }

  private saveDataToStorage(response: LoginResponse) {
    this.saveUser(response.user);
    this.saveAccessToken(response.access_token);
    this.saveRefreshToken(response.refresh_token);
  }

  private saveAccessToken(token: string) {
    localStorage.setItem('access_token', token);
    this.readAccessToken();
  }

  private saveRefreshToken(token: string) {
    localStorage.setItem('refresh_token', token);
  }

  private saveUser(user: User) {
    localStorage.setItem('user_identity', JSON.stringify(user));
  }

  private readUser(): User {
    this.user = new User().hydrateWith(JSON.parse(localStorage.getItem('user_identity')));

    return this.user;
  }

  private readStorageData() {
    this.readUser();
    this.readAccessToken();
    this.readRefreshToken();
  }

  private readAccessToken() {
    this.accessToken = localStorage.getItem('access_token');

    return this.accessToken;
  }

  private readRefreshToken() {
    this.refreshToken = localStorage.getItem('refresh_token');

    return this.refreshToken;
  }

  isLoggedIn() {
    return !!this.accessToken;
  }
}
