import { UserRetrieveResponse } from './../interfaces/user-retriete-response.interface';
import { User } from './../models/user.model';
import { map } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class UserService {

  constructor(private http: HttpClient) { }

  getUser() {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json'
    });

    return this.http.get(`${environment.apiUrl}/user/retrieve`, {headers}).pipe(
      map((response: UserRetrieveResponse) => {
        return new User().hydrateWith(response.user);
      })
    );
  }
}
