import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-loading',
  template: `
    <div class="swal2-actions swal2-loading animated fadeIn fast" style="display: flex;">
      <button
          type="button"
          class="swal2-confirm swal2-styled"
          style="display: inline-block; border-left-color: rgb(48, 133, 214); border-right-color: rgb(48, 133, 214);">
    </button>
      </div>
  `,
  styles: []
})
export class LoadingComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
