import { ShippingService } from './../../../services/shipping.service';
import { Router } from '@angular/router';
import { AuthService } from './../../../services/auth.service';
import { User } from './../../../models/user.model';
import { Component, OnInit } from '@angular/core';
import Swal from 'sweetalert2';
import { NgForm } from '@angular/forms';
import { HttpErrorResponse } from '@angular/common/http';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styles: []
})
export class NavbarComponent implements OnInit {

  user: User;

  shipmentId: string;

  constructor(
    private auth: AuthService,
    private shippingService: ShippingService,
    private router: Router
  ) {}

  ngOnInit() {
    this.user = this.auth.getUser();
  }

  logout() {
    Swal.fire({
      icon: 'info',
      title: 'Procesando',
      allowOutsideClick: false,
      allowEscapeKey: false
    });

    Swal.showLoading();

    this.auth.logout().subscribe((response) => {
      Swal.fire({
        icon: 'success',
        title: 'Se ha cerrado la sesión con éxito'
      });

      this.router.navigate(['/login']);
    });
  }

  onSubmit(form: NgForm) {
    if (form.invalid) {
      Swal.fire({
        icon: 'error',
        title: 'Error',
        text: 'Ingrese una etiqueta para buscar.'
      });

      return;
    }

    Swal.fire({
      icon: 'info',
      title: 'Buscando...',
      allowOutsideClick: false,
      allowEscapeKey: false
    });

    Swal.showLoading();

    this.shippingService.search(this.shipmentId).subscribe(
      (manifestId: number) => {
        this.router.navigate(['/manifest', manifestId]);
        Swal.close();
      },
      (errorResponse: any) => {
        Swal.fire({
          icon: 'error',
          title: 'Error',
          text: this.getErrorMessage(errorResponse)
        });
      }
    );
  }

  getErrorMessage(errorResponse: any) {
    if (errorResponse instanceof HttpErrorResponse) {
      return errorResponse.error;
    }

    return 'Ha ocurrido un error';
  }
}
