import { DashboardState } from './../../models/dashboard-state.model';
import { map } from 'rxjs/operators';
import { ActivatedRoute, Router } from '@angular/router';
import { DatePeriod } from './../../models/date-period.model';
import { ManifestListComponent } from './../shared/manifest-list/manifest-list.component';
import { Component, OnInit, ViewChild, AfterViewInit, ElementRef } from '@angular/core';
import { DashboardDataItem } from 'src/app/models/dashboard-data-item.model';
import { DashboardChartComponent } from '../shared/dashboard-chart/dashboard-chart.component';

import { Subject } from 'rxjs';
import * as moment from 'moment';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styles: []
})
export class DashboardComponent implements OnInit, AfterViewInit {

  @ViewChild(DashboardChartComponent, {static: false}) chart: DashboardChartComponent;
  @ViewChild(ManifestListComponent, {static: false}) manifestList: ManifestListComponent;
  @ViewChild('fromDateInput', { static: false }) fromDateInput: ElementRef;
  @ViewChild('toDateInput', { static: false }) toDateInput: ElementRef;

  nowDate: moment.Moment;

  // Propiedades base que componen el state.
  fromDate: moment.Moment;
  toDate: moment.Moment;
  manifestsPage: number;

  // Propiedades bindeadas a los campos del formulario.
  fromDateForm: moment.Moment;
  toDateForm: moment.Moment;

  processingChart: boolean;
  processingManifests: boolean;
  processing: boolean;

  // Estado.
  state: DashboardState;
  dashboardState$: Subject<DashboardState> = new Subject();

  constructor(
    private router: Router,
    private route: ActivatedRoute
  ) {
    this.processingChart = true;
    this.processingManifests = true;
    this.processing = this.processingChart || this.processingManifests ? true : false;
  }

  ngOnInit() {
    this.route.queryParams.subscribe(params => {
      const fromDateParam = params.fromDate;
      const toDateParam = params.toDate;
      const pageParam = params.page;

      this.nowDate = moment();

      this.fromDate = fromDateParam ? moment(fromDateParam).startOf('day') : moment().subtract(30, 'days').startOf('day');
      this.toDate = toDateParam ? moment(toDateParam).endOf('day') : moment().endOf('day');
      this.manifestsPage = pageParam ? Number(pageParam) : 1;

      this.updateState();

      if (this.state.datePeriod.invalid) {
        this.processing = false;
        return;
      }

      if (!fromDateParam || !toDateParam || !pageParam) {
        this.updateQueryString();
        return;
      }

      this.fromDateForm = this.fromDate.clone();
      this.toDateForm = this.toDate.clone();

      this.dashboardState$.next(this.state);
    });
  }

  ngAfterViewInit() {
    this.updateState();

    if (this.state.datePeriod.invalid) {
      this.processing = false;
      return;
    }

    this.dashboardState$.next(this.state);
  }

  toMomentFrom(date: string): moment.Moment {
    if (!date) {
      this.fromDateInput.nativeElement.value = this.fromDateForm.format('YYYY-MM-DD');
      return this.fromDateForm;
    }
    return moment(date).startOf('day');
  }

  toMomentTo(date: string): moment.Moment {
    if (!date) {
      this.toDateInput.nativeElement.value = this.toDateForm.format('YYYY-MM-DD');
      return this.toDateForm;
    }
    return moment(date).endOf('day');
  }

  onProcessingChart(processing: boolean) {
    this.processingChart = processing;
    this.processing = this.processingChart || this.processingManifests ? true : false;

    if (!this.processing) {
      Swal.close();
    }
  }

  onProcessingManifests(processing) {
    this.processingManifests = processing;
    this.processing = this.processingChart || this.processingManifests ? true : false;

    if (!this.processing) {
      Swal.close();
    }
  }

  dateRangeSubmit() {
    const now = moment();

    const newFromDate = moment(this.fromDateForm).startOf('day');
    const newToDate = moment(this.toDateForm).endOf('day');

    const newDatePeriod = new DatePeriod(newFromDate, newToDate);

    if (newDatePeriod.invalid) {
      Swal.fire({
        icon: 'error',
        title: 'Error',
        text: newDatePeriod.error
      });
      return;
    }

    this.fromDate = newFromDate;
    this.toDate = newToDate;

    // Al cambiar el período de fechas, seteamos la página de manifiestos
    // en 1, porque se trata de una nueva búsqueda.
    this.state.manifestsPage = 1;

    // Seteamos la propiedad "force" del estado y hacemos un llamado al next
    // del estado para forzar la actualización de los componentes suscriptos
    // porque sería el comportamiento esperado al buscar.
    this.state.force = true;

    this.updateQueryString().then(() => this.dashboardState$.next(this.state));
  }

  updateDate(subtract: number) {
    if (this.processing) {
      Swal.fire({
        icon: 'info',
        title: 'Procesando',
        text: 'Por favor espere'
      });
      Swal.showLoading();
      return;
    }
    if (subtract === 1) {
      this.fromDate = moment().subtract(1, 'days').startOf('day');
      this.toDate = moment().subtract(1, 'days').endOf('day');
    } else if (subtract === 0) {
      this.fromDate = moment().startOf('day');
      this.toDate = moment().endOf('day');
    } else {
      this.fromDate = moment().subtract(subtract, 'days').startOf('day');
      this.toDate = moment().endOf('day');
    }

    this.fromDateForm = moment(this.fromDate).startOf('day');
    this.toDateForm = moment(this.toDate).endOf('day');

    // Al cambiar el período de fechas, seteamos la página de manifiestos
    // en 1, porque se trata de una nueva búsqueda.
    this.state.manifestsPage = 1;

    this.updateQueryString();
  }

  private updateState() {
    const newDatePeriod = new DatePeriod(this.fromDate, this.toDate);

    this.state = new DashboardState(newDatePeriod, this.manifestsPage);

    if (this.state.invalid) {
      Swal.fire({
        icon: 'error',
        title: 'Error',
        text: this.state.error
      });
    }
  }

  private updateQueryString() {
    return this.router.navigate([], {
      relativeTo: this.route,
      queryParams: {
        fromDate: this.fromDate.format('YYYY-MM-DD'),
        toDate: this.toDate.format('YYYY-MM-DD'),
        page: this.manifestsPage
      },
      queryParamsHandling: 'merge'
    });
  }

  get dateDiff() {
    return this.toDate.diff(this.fromDate, 'days');
  }

  get fromDateIsToday() {
    return this.fromDate.isSame(moment(), 'day');
  }

  get fromDateIsYesterday() {
    return this.fromDate.isSame(moment().subtract(1, 'days'), 'day');
  }

  get toDateIsToday() {
    return this.toDate.isSame(moment(), 'day');
  }

  get toDateIsYesterday() {
    return this.toDate.isSame(moment().subtract(1, 'days'), 'day');
  }
}
