import { AuthService } from './../../services/auth.service';
import { User } from './../../models/user.model';
import { Component, OnInit, Renderer2, OnDestroy } from '@angular/core';
import { NgForm } from '@angular/forms';
import { HttpErrorResponse } from '@angular/common/http';

import Swal from 'sweetalert2';
import { LoginResponse } from 'src/app/interfaces/login-response.interface';
import { Router, ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: []
})
export class LoginComponent implements OnInit, OnDestroy {

  user: User;

  remember: boolean;

  returnUrl: string;

  constructor(
    private auth: AuthService,
    private router: Router,
    private route: ActivatedRoute,
    private renderer: Renderer2
  ) {
    this.user = new User();

    const rememberedEmail = localStorage.getItem('rememberedEmail');

    this.remember = !!rememberedEmail;

    if (this.remember) {
      this.user.email = rememberedEmail;
    }
  }

  ngOnInit() {
    this.renderer.addClass(document.body, 'app-outside');

    this.returnUrl = this.route.snapshot.queryParams.returnUrl || '/dashboard';
  }

  ngOnDestroy() {
    this.renderer.removeClass(document.body, 'app-outside');
  }

  onSubmit(form: NgForm) {
    if (form.invalid) {
      Swal.fire({
        icon: 'error',
        title: 'Error',
        text: 'El formulario contiene errores.'
      });

      return;
    }

    Swal.fire({
      icon: 'info',
      title: 'Procesando',
      allowOutsideClick: false,
      allowEscapeKey: false
    });

    Swal.showLoading();

    this.auth.login(this.user).subscribe(
      (response: LoginResponse) => {
        Swal.close();

        if (this.remember) {
          localStorage.setItem('rememberedEmail', this.user.email);
        } else {
          localStorage.removeItem('rememberedEmail');
        }

        this.router.navigateByUrl(this.returnUrl);
      },
      (errorResponse: any) => {
        Swal.fire({
          icon: 'error',
          title: 'Error al autenticar',
          text: this.getErrorMessage(errorResponse)
        });
      }
    );
  }

  getErrorMessage(errorResponse: any) {
    if (errorResponse instanceof HttpErrorResponse) {
      return errorResponse.error;
    }

    return 'Ha ocurrido un error';
  }
}
