import { User } from './../../../models/user.model';
import { Component, OnInit, Renderer2, Input } from '@angular/core';

@Component({
  selector: 'app-tawkto',
  template: ``,
  styles: []
})
export class TawktoComponent implements OnInit {

  @Input() id: string;

  @Input() user: User;

  constructor(
    private renderer: Renderer2
  ) { }

  ngOnInit(): void {
    const s = this.renderer.createElement('script');
    s.text = `var Tawk_API = Tawk_API || {}, Tawk_LoadStart = new Date();
    (function () {
      var s1 = document.createElement("script"), s0 = document.getElementsByTagName("script")[0];
      s1.async = true;
      s1.src = 'https://embed.tawk.to/${this.id}/default';
      s1.charset = 'UTF-8';
      s1.setAttribute('crossorigin', '*');
      s0.parentNode.insertBefore(s1, s0);
      Tawk_API.visitor = {
        name  : '${this.user.fullName}',
        email : '${this.user.email}'
      };
    })();`;
    this.renderer.appendChild(document.body, s);
  }

}
