import { map } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { environment } from '../../environments/environment';
import { ShippingSearchResponse } from '../interfaces/shipping-search-response.iterface';

@Injectable({
  providedIn: 'root'
})
export class ShippingService {

  constructor(
    private http: HttpClient
  ) { }

  search(shipmentId: string) {
    return this.http.get(`${environment.apiUrl}/shipping/search?shipment_id=${shipmentId}`).pipe(
      map((response: ShippingSearchResponse) => response.data.manifestId)
    );
  }
}
