import { ManifestListResponse } from './../interfaces/manifest-list-response';
import { PaginationData } from './pagination-data.model';
import { Manifest } from './manifest.model';
import { Hydratable } from './../interfaces/hydratable.interface';

export class ManifestList implements Hydratable {
  manifests: Manifest[] = [];
  paginationData: PaginationData;

  hydrateWith(data: ManifestListResponse): this {
    this.manifests = data.data.map(manifestData => new Manifest().hydrateWith(manifestData));
    this.paginationData = new PaginationData().hydrateWith(data.paginationData);

    return this;
  }
}
