import { Hydratable } from './../interfaces/hydratable.interface';

export class PaginationData implements Hydratable {
  last: number;
  current: number;
  numItemsPerPage: number;
  first: number;
  pageCount: number;
  totalCount: number;
  pageRange: number;
  startPage: number;
  endPage: number;
  pagesInRange: number[];
  firstPageInRange: number;
  lastPageInRange: number;
  currentItemCount: number;
  firstItemNumber: number;
  lastItemNumber: number;

  hydrateWith(data: any): this {
    return Object.assign(this, data);
  }
}
