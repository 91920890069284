import { RefreshTokenResponse } from './../interfaces/refresh-token-response.interface';
import { Router } from '@angular/router';
import { AuthService } from './../services/auth.service';
import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpErrorResponse
} from '@angular/common/http';
import { Observable, BehaviorSubject, throwError } from 'rxjs';
import { catchError, switchMap, filter, take } from 'rxjs/operators';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {

  private isRefreshing = false;
  private refreshTokenSubject: BehaviorSubject<any> = new BehaviorSubject<any>(null);

  constructor(
    private auth: AuthService,
    private router: Router
  ) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    let accessToken = this.auth.getAccessToken();

    accessToken = accessToken ? accessToken : '';

    request = this.addAccessTokenHeader(request, accessToken);

    return next.handle(request).pipe(
      catchError(error => {
          if (error instanceof HttpErrorResponse && error.status === 401) {
            return this.handle401Error(request, next);
          } else {
            return throwError(error);
          }
      })
    );
  }

  addAccessTokenHeader(request: HttpRequest<any>, accessToken: string) {
    return request.clone({
      setHeaders: {
        Authorization: accessToken
      }
    });
  }

  private handle401Error(req: HttpRequest<any>, next: HttpHandler) {
    if (!this.isRefreshing) {
      this.isRefreshing = true;
      this.refreshTokenSubject.next(null);

      return this.auth.refreshAccessToken().pipe(
        catchError((error) => {
          this.isRefreshing = false;

          if (error instanceof HttpErrorResponse && error.status === 403) {
              const requiredUrl = this.router.routerState.snapshot.url;

              this.auth.clearStorageData();

              this.router.navigate(['/login'], {
                  queryParams: {
                      returnUrl: requiredUrl
                  }
              });
            }

          return throwError(error);
        }),
        switchMap((response: RefreshTokenResponse) => {
          const newAccessToken = response.access_token;

          this.isRefreshing = false;
          this.refreshTokenSubject.next(newAccessToken);
          return next.handle(this.addAccessTokenHeader(req, newAccessToken));
        })
      );
    } else {
      return this.refreshTokenSubject.pipe(
        filter(token => token != null),
        take(1),
        switchMap((token: string) => {
            return next.handle(this.addAccessTokenHeader(req, token));
        })
      );
    }
  }
}
