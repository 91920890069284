import { Hydratable } from './../interfaces/hydratable.interface';

export class DashboardDataItem implements Hydratable{

  day: string;
  manifestCount: number;
  shippingCount: number;

  hydrateWith(data: any): this {
    return Object.assign(this, data);
  }
}
