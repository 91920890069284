import { DatePeriod } from './date-period.model';
export class DashboardState {

  readonly valid: boolean;
  readonly invalid: boolean;
  error: string;
  force: boolean;

  constructor(
    public datePeriod: DatePeriod,
    public manifestsPage: number
  ) {
    this.valid = this.datePeriod.valid;
    this.invalid = this.datePeriod.invalid;
    this.error = this.datePeriod.error;
    this.force = false;
  }
}
