import * as moment from 'moment';

export class DatePeriod {

  readonly valid: boolean;
  readonly invalid: boolean;
  error: string;

  constructor(
    public fromDate: moment.Moment,
    public toDate: moment.Moment
  ) {
    this.valid = this.isValid();
    this.invalid = !this.valid;
  }

  get diffDays(): number {
    return this.toDate.diff(this.fromDate, 'days');
  }

  isSame(datePeriod: DatePeriod) {
    return this.toDate.isSame(datePeriod.toDate, 'day') && this.fromDate.isSame(datePeriod.fromDate, 'day');
  }

  private isValid(): boolean {
    if (!this.fromDate.isValid()) {
      this.error = 'La fecha "desde" no es válida';
      return false;
    }

    if (!this.toDate.isValid()) {
      this.error = 'La fecha "hasta" no es válida';
      return false;
    }

    if (this.fromDate.isAfter(this.toDate)) {
      this.error = 'La fecha "desde" debe ser anterior o igual a la fecha "hasta"';
      return false;
    }

    if (this.toDate.isAfter(moment().endOf('day'))) {
      this.error = 'La fecha "hasta" debe ser anterior o igual a la fecha de hoy';
      return false;
    }

    if (this.toDate.diff(this.fromDate, 'days') > 366) {
      this.error = 'El período máximo permitido es de 1 año';
      return false;
    }

    return true;
  }
}
