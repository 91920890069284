import { AuthService } from './../../services/auth.service';
import { User } from './../../models/user.model';
import { Component, OnInit, Renderer2, OnDestroy } from '@angular/core';
import { NgForm } from '@angular/forms';
import Swal from 'sweetalert2';
import { HttpErrorResponse } from '@angular/common/http';

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styles: []
})
export class RegisterComponent implements OnInit, OnDestroy {

  email: string;

  registerSuccess = false;

  constructor(
    private auth: AuthService,
    private renderer: Renderer2
  ) {}

  ngOnInit() {
    this.renderer.addClass(document.body, 'app-outside');
  }

  ngOnDestroy() {
    this.renderer.removeClass(document.body, 'app-outside');
  }

  onSubmit(form: NgForm) {
    this.registerSuccess = false;

    if (form.invalid) {
      Swal.fire({
        icon: 'error',
        title: 'Error',
        text: 'El formulario contiene errores.'
      });
      return;
    }

    Swal.fire({
      icon: 'info',
      title: 'Procesando',
      allowOutsideClick: false,
      allowEscapeKey: false
    });

    Swal.showLoading();

    this.auth.register(this.email).subscribe(
      (response: any) => {
        Swal.fire({
          icon: 'success',
          title: 'Registro exitoso',
          text: `Hemos enviado un email a ${this.email} con instrucciones para activar la cuenta.`,
          allowEscapeKey: false,
          allowOutsideClick: false,
          showConfirmButton: false
        });

        this.registerSuccess = true;
        form.reset();
      },
      (errorResponse: any) => {
        Swal.fire({
          icon: 'error',
          title: 'Error',
          text: this.getErrorMessage(errorResponse)
        });
      }
    );
  }

  getErrorMessage(errorResponse: any) {
    if (errorResponse instanceof HttpErrorResponse) {
      return errorResponse.error;
    }

    return 'Ha ocurrido un error';
  }
}
