import { Hydratable } from './../interfaces/hydratable.interface';

export class User implements Hydratable{
  id: number;
  name: string;
  surname: string;
  nickname: string;
  email: string;
  meliId: string;
  password: string;

  get fullName(): string {
    return `${this.name} ${this.surname}`;
  }

  hydrateWith(data: any): this {
    return Object.assign(this, data);
  }
}
