import { DashboardState } from './../../../models/dashboard-state.model';
import { ActivatedRoute, Router } from '@angular/router';
import { DatePeriod } from './../../../models/date-period.model';
import { Manifest } from './../../../models/manifest.model';
import { ManifestService } from './../../../services/manifest.service';
import { Component, OnInit, Input, Output, EventEmitter, OnDestroy } from '@angular/core';

import * as moment from 'moment';
import { ManifestList } from 'src/app/models/manifest-list.model';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-manifest-list',
  templateUrl: './manifest-list.component.html',
  styles: []
})
export class ManifestListComponent implements OnInit, OnDestroy {

  @Input() dashboardState: Subject<DashboardState>;

  @Output() processingEmitter: EventEmitter<boolean> = new EventEmitter();

  datePeriod: DatePeriod;

  manifestList: ManifestList;

  public loadingData: boolean;
  public processing: boolean;
  currentPage: number;

  errorMsg: string;

  constructor(
    private manifestService: ManifestService,
    private route: ActivatedRoute,
    private router: Router
  ) {
    this.manifestList = new ManifestList();
    this.loadingData = true;
    this.processing = false;
    this.processingEmitter.emit(this.processing);
  }

  ngOnInit() {
    this.dashboardState.subscribe((dashboardState: DashboardState) => {
      // Si el período de fechas y la página no cambiaron, no hacemos nada. Se tiene en cuenta
      // también la propiedad "force" del estado.
      if (this.datePeriod && !dashboardState.force && this.datePeriod.isSame(dashboardState.datePeriod) && this.currentPage === dashboardState.manifestsPage) {
        return;
      }

      // Si la página es menor a 1, navego a la página 1.
      if (dashboardState.manifestsPage < 1) {
        this.goToPage(1);
        return;
      }

      this.datePeriod = dashboardState.datePeriod;
      this.currentPage = dashboardState.manifestsPage;

      this.getManifests();
    });
  }

  ngOnDestroy() {
    this.dashboardState.unsubscribe();
  }

  getManifests() {
    if (this.processing) {
      this.errorMsg = 'Procesando, por favor espere';
      return;
    }

    this.loadingData = true;
    this.processing = true;
    this.processingEmitter.emit(this.processing);

    this.manifestService.getManifests(this.datePeriod.fromDate, this.datePeriod.toDate, this.currentPage).subscribe(
      (manifestList: ManifestList) => {
        this.manifestList = manifestList;

        this.processing = false;
        this.loadingData = false;
        this.processingEmitter.emit(this.processing);

        if (this.currentPage > manifestList.paginationData.pageCount) {
          this.goToPage(manifestList.paginationData.pageCount);
        }
      },
      (errorResponse: any) => {
        this.loadingData = false;
        this.processing = false;
        this.processingEmitter.emit(this.processing);
      }
    );
  }

  goToPage(page: number) {
    return this.router.navigate([], {
      relativeTo: this.route,
      queryParams: {
        page
      },
      queryParamsHandling: 'merge'
    });
  }
}
