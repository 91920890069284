import { AuthService } from './../../services/auth.service';
import { User } from './../../models/user.model';
import { Component, OnInit, OnDestroy, Renderer2 } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgForm } from '@angular/forms';
import Swal from 'sweetalert2';
import { LoginResponse } from 'src/app/interfaces/login-response.interface';
import { HttpErrorResponse } from '@angular/common/http';

@Component({
  selector: 'app-activate',
  templateUrl: './activate.component.html',
  styles: []
})
export class ActivateComponent implements OnInit, OnDestroy {

  user: User;
  password2: string;
  activationToken: string;

  constructor(
    private auth: AuthService,
    private route: ActivatedRoute,
    private router: Router,
    private renderer: Renderer2
  ) {
    this.user = new User();

    this.user.id = Number(this.route.snapshot.paramMap.get('userId'));
    this.activationToken = this.route.snapshot.paramMap.get('token');
  }

  ngOnInit() {
    this.renderer.addClass(document.body, 'app-outside');
  }

  ngOnDestroy() {
    this.renderer.removeClass(document.body, 'app-outside');
  }

  onSubmit(form: NgForm) {
    if (form.invalid) {
      Swal.fire({
        icon: 'error',
        title: 'Error',
        text: 'El formulario contiene errores.'
      });

      return;
    }

    if (this.user.password !== this.password2) {
      Swal.fire({
        icon: 'error',
        title: 'Error',
        text: 'Las contraseñas no coinciden.'
      });

      this.password2 = '';

      return;
    }

    Swal.fire({
      icon: 'info',
      title: 'Procesando',
      allowOutsideClick: false,
      allowEscapeKey: false
    });

    Swal.showLoading();

    this.auth.activate(this.user, this.activationToken).subscribe(
      (response: LoginResponse) => {
        Swal.close();
        this.router.navigate(['/dashboard']);
      },
      (errorResponse: any) => {
        Swal.fire({
          icon: 'error',
          title: 'Error al activar la cuenta',
          text: this.getErrorMessage(errorResponse)
        });
      }
    );
  }

  getErrorMessage(errorResponse: any) {
    if (errorResponse instanceof HttpErrorResponse) {
      return errorResponse.error;
    }

    return 'Ha ocurrido un error';
  }
}
