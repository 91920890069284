import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-footer',
  template: `
    <footer>
      <div class="float-left">
        <a href="http://softlight.com.ar/" target="_blank" title="Softlight">
          <img src="assets/img/logo-softlight-tr-sm.png" alt="Softlight">
        </a>
      </div>
    </footer>
  `,
  styles: [`
    footer {
      position: fixed;
      bottom: 0;
      left: 0;
      right: 0;
      background-color: #2c2c2c;
      border-top: 1px solid #CBD3D7;
      padding: 3px 20px;
    }
    footer a {
      display: block;
      height: 39px;
      width: 150px;
    }
    footer a img {
      display: block;
      width: 100%;
    }
  `]
})
export class FooterComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
