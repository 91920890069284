import { ManifestResponse } from './../interfaces/manifest-response.interface';
import { ManifestList } from './../models/manifest-list.model';
import { ManifestListResponse } from './../interfaces/manifest-list-response';
import { Manifest } from './../models/manifest.model';
import { map } from 'rxjs/operators';
import { Injectable } from '@angular/core';

import { environment } from '../../environments/environment';
import { HttpClient } from '@angular/common/http';

import * as moment from 'moment';

@Injectable({
  providedIn: 'root'
})
export class ManifestService {

  constructor(
    private http: HttpClient
  ) { }

  getManifests(fromDate: moment.Moment = null, toDate: moment.Moment = null, page: number = 1) {
    const queryStringVars = [];
    let queryString = '';

    if (fromDate !== null) {
        queryStringVars.push('from_date=' + fromDate.format('YYYY-MM-DD'));
    }
    if (toDate !== null) {
        queryStringVars.push('to_date=' + toDate.format('YYYY-MM-DD'));
    }

    queryStringVars.push('page=' + page);

    queryString = queryStringVars.join('&');

    return this.http.get(`${environment.apiUrl}/manifest/?${queryString}`).pipe(
      map((response: ManifestListResponse) => new ManifestList().hydrateWith(response))
    );
  }

  getManifest(id: number) {
    return this.http.get(`${environment.apiUrl}/manifest/${id}`).pipe(
      map((response: ManifestResponse) => new Manifest().hydrateWith(response.data))
    );
  }
}
