import { Shipping } from './shipping.model';
import { Hydratable } from './../interfaces/hydratable.interface';

import * as moment from 'moment';

export class Manifest implements Hydratable {
  id: number;
  createdAt: moment.Moment;
  updatedAt: moment.Moment;
  shipmentBatchId: string;
  date: moment.Moment;
  shippingCount?: number;
  shippings?: Shipping[];

  hydrateWith(data: any): this {
    data.createdAt = data.createdAt ? moment(data.createdAt) : null;
    data.updatedAt = data.updatedAt ? moment(data.updatedAt) : null;
    data.date = moment(data.date);

    Object.assign(this, data);

    if (typeof data.shippings !== 'undefined') {
      this.shippings = data.shippings.map(shippingData => new Shipping().hydrateWith(shippingData));
    }

    return this;
  }

  toCsv(delimiter: string = ',') {
    const csvHeader = `Etiqueta${delimiter}Número de Orden${delimiter}Usuario de Compra\r\n`;

    return csvHeader + this.shippings.map(
      (shipping: Shipping) => `${shipping.shipmentId}${delimiter}${shipping.orderId}${delimiter}${shipping.receiverNickname}`
    ).join('\r\n');
  }
}
