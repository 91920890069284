import { AuthService } from './../../services/auth.service';
import { User } from './../../models/user.model';
import { UserService } from './../../services/user.service';
import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';

import Swal from 'sweetalert2';
import { HttpErrorResponse } from '@angular/common/http';

@Component({
  selector: 'app-user-detail',
  templateUrl: './user-detail.component.html',
  styleUrls: []
})
export class UserDetailComponent implements OnInit {

  user: User;

  loading: boolean;

  newPassword: string;
  newPassword2: string;

  constructor(
    private userService: UserService,
    private authService: AuthService
  ) { }

  ngOnInit(): void {
    this.loading = true;

    this.userService.getUser().subscribe((user: User) => {
      this.user = user;
      this.loading = false;
    });
  }

  onSubmit(form: NgForm) {
    if (form.invalid) {
      Swal.fire({
        icon: 'error',
        title: 'Error',
        text: 'El formulario contiene errores.'
      });

      return;
    }

    if (this.newPassword !== this.newPassword2) {
      Swal.fire({
        icon: 'error',
        title: 'Error',
        text: 'Las contraseñas no coinciden.'
      });

      return;
    }

    Swal.fire({
      icon: 'info',
      title: 'Procesando',
      allowOutsideClick: false,
      allowEscapeKey: false
    });

    Swal.showLoading();

    this.authService.changePassword(this.user.password, this.newPassword).subscribe(
      (response: any) => {
        Swal.fire({
          icon: 'success',
          title: 'Contraseña actualizada'
        });

        this.user.password = '';
        this.newPassword = '';
        this.newPassword2 = '';
      },
      (errorResponse: any) => {
        Swal.fire({
          icon: 'error',
          title: 'Error',
          text: this.getErrorMessage(errorResponse)
        });
      }
    );
  }

  getErrorMessage(errorResponse: any) {
    console.log(errorResponse);

    if (errorResponse instanceof HttpErrorResponse) {
      return errorResponse.error;
    }

    return 'Ha ocurrido un error';
  }
}
