import { DatePeriod } from './../models/date-period.model';
import { Pipe, PipeTransform } from '@angular/core';

import * as moment from 'moment';
import { formatDate } from '@angular/common';

@Pipe({
  name: 'datePeriod'
})
export class DatePeriodPipe implements PipeTransform {

  transform(datePeriod: DatePeriod): string {
    const fromDate = datePeriod.fromDate;
    const toDate = datePeriod.toDate;

    if (fromDate.year() === toDate.year()) {
      if (fromDate.month() === toDate.month()) {
        if (fromDate.date() === toDate.date()) {
          const value: string = formatDate(fromDate.toDate(), 'EEEE d \'de\' MMMM \'de\' y', 'es');

          return value.charAt(0).toUpperCase() + value.slice(1);
        }
        return `Del ${formatDate(fromDate.toDate(), 'EEEE d', 'es')} al ${formatDate(toDate.toDate(), 'EEEE d \'de\' MMMM \'de\' y', 'es')}`;
      }
      return `Del ${formatDate(fromDate.toDate(), 'EEEE d \'de\' MMMM', 'es')} al ${formatDate(toDate.toDate(), 'EEEE d \'de\' MMMM \'de\' y', 'es')}`;
    }
    return `Del ${formatDate(fromDate.toDate(), 'EEEE d \'de\' MMMM \'de\' y', 'es')} al ${formatDate(toDate.toDate(), 'EEEE d \'de\' MMMM \'de\' y', 'es')}`;
  }

}
