import { Routes, RouterModule } from '@angular/router';
import { NgModule } from '@angular/core';

import { AuthGuard } from './guards/auth.guard';
import { AuthNotGuard } from './guards/auth-not.guard';

import { ResetPasswordComponent } from './components/reset-password/reset-password.component';
import { RecoverComponent } from './components/recover/recover.component';
import { ManifestComponent } from './components/manifest/manifest.component';
import { ActivateComponent } from './components/activate/activate.component';
import { RegisterComponent } from './components/register/register.component';
import { UserDetailComponent } from './components/user-detail/user-detail.component';
import { LoginComponent } from './components/login/login.component';
import { DashboardComponent } from './components/dashboard/dashboard.component';


const routes: Routes = [
  { path: 'dashboard', component: DashboardComponent, canActivate: [AuthGuard] },
  { path: 'user', component: UserDetailComponent, canActivate: [AuthGuard] },
  { path: 'login', component: LoginComponent, canActivate: [AuthNotGuard] },
  { path: 'register', component: RegisterComponent, canActivate: [AuthNotGuard] },
  { path: 'recover', component: RecoverComponent, canActivate: [AuthNotGuard] },
  { path: 'activate/:userId/:token', component: ActivateComponent, canActivate: [AuthNotGuard] },
  { path: 'reset-password/:userId/:token', component: ResetPasswordComponent, canActivate: [AuthNotGuard] },
  { path: 'manifest/:id', component: ManifestComponent, canActivate: [AuthGuard] },
  { path: '**', pathMatch: 'full', redirectTo: 'dashboard' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
