import { DashboardState } from './../../../models/dashboard-state.model';
import { DatePeriod } from './../../../models/date-period.model';
import { Subject } from 'rxjs';
import { Component, OnInit, Input, Output, EventEmitter, OnChanges, SimpleChanges, OnDestroy } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';

import { ChartDataSets, ChartOptions, ChartType } from 'chart.js';
import { Color, Label } from 'ng2-charts';

import { DashboardService } from '../../../services/dashboard.service';

import * as moment from 'moment';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-dashboard-chart',
  templateUrl: './dashboard-chart.component.html',
  styleUrls: ['./dashboard-chart.component.css']
})
export class DashboardChartComponent implements OnInit, OnDestroy {

  @Input() dashboardState: Subject<DashboardState>;

  @Output() processingEmitter: EventEmitter<boolean> = new EventEmitter();

  datePeriod: DatePeriod;

  showChart: boolean;

  public lineChartData: ChartDataSets[] = [
      {data: [], label: 'Cargando...'},
  ];

  public lineChartLabels: Label[] = [];

  public lineChartOptions: ChartOptions = {
      responsive: true,
      maintainAspectRatio: false,
      tooltips: {
        mode: 'index',
        intersect: false,
          callbacks: {
            title: (items, data) => {
              return items[0].xLabel.toString();
            },
            label: (item, data) => {
              return data.datasets[item.datasetIndex].label + ': ' + item.yLabel;
            }
          }

      },
      scales: {
          xAxes: [
              {
                  type: 'time',
                  distribution: 'series',
                  time: {
                      tooltipFormat: 'DD/MM/YYYY',
                      displayFormats: {
                          day: 'D/MM'
                      }
                  },
                  gridLines: {
                    display: false
                  }
              }
          ],
          yAxes: [
              {
                  id: 'y-axis-count-1',
                  position: 'left',
                  ticks: {
                      beginAtZero: true,
                      stepSize: 1
                  },
                  scaleLabel: {
                    display: true,
                    labelString: 'Manifiestos'
                  }
              },
              {
                  id: 'y-axis-count-2',
                  position: 'right',
                  ticks: {
                      beginAtZero: true
                  },
                  scaleLabel: {
                    display: true,
                    labelString: 'Paquetes'
                  }
              }
          ]
      }
  };

  public lineChartColors: Color[] = [
      {
          backgroundColor: '#b1c7bc61',
      },
  ];

  public lineChartLegend = true;
  public lineChartType: ChartType = 'line';
  public lineChartPlugins = [];

  public loadingData: boolean;
  public processing: boolean;
  public errorMsg: string;

  public totalIncome: number;
  public totalManifestCount: number;
  public totalShippingCount: number;

  constructor(
      private dashboardService: DashboardService
  ) {
    this.loadingData = true;
    this.processing = false;
    this.processingEmitter.emit(this.processing);
    this.errorMsg = '';
  }

  ngOnInit() {
    this.dashboardState.subscribe((dashboardState: DashboardState) => {
      // Si el período de fechas no cambió, no hacemos nada.
      if (this.datePeriod && !dashboardState.force && this.datePeriod.isSame(dashboardState.datePeriod)) {
        return;
      }

      this.datePeriod = dashboardState.datePeriod;

      this.showChart = this.datePeriod.diffDays > 1;

      this.getChartData();
    });
  }

  ngOnDestroy() {
    this.dashboardState.unsubscribe();
  }

  getChartData() {
    if (this.processing) {
      this.errorMsg = 'Procesando, por favor espere';
      return;
    }

    this.loadingData = true;
    this.processing = true;
    this.processingEmitter.emit(this.processing);

    this.dashboardService.getData(this.datePeriod.fromDate, this.datePeriod.toDate).subscribe(
      (data: any[]) => {
        this.loadingData = false;
        this.processing = false;
        this.processingEmitter.emit(this.processing);

        let chartDataManifestCount = [];
        let chartDataShippingCount = [];
        let chartLabels = [];
        let totalIncome = 0;
        let totalManifestCount = 0;
        let totalShippingCount = 0;

        for (let dataset of data) {
          chartDataManifestCount.push(dataset.manifestCount);
          chartDataShippingCount.push(dataset.shippingCount);
          chartLabels.push(dataset.day);

          totalIncome += dataset.income;
          totalManifestCount += dataset.manifestCount;
          totalShippingCount += dataset.shippingCount;
        }

        this.totalIncome = totalIncome;
        this.totalManifestCount = totalManifestCount;
        this.totalShippingCount = totalShippingCount;

        this.lineChartData = [
          {
            data: chartDataManifestCount,
            label: 'Manifiestos',
            yAxisID: 'y-axis-count-1',
            lineTension: 0.3
          },
          {
            data: chartDataShippingCount,
            label: 'Paquetes',
            yAxisID: 'y-axis-count-2',
            lineTension: 0.3
          }
        ];

        this.lineChartLabels = chartLabels;
      },
      (errorResponse: any) => {
        this.loadingData = false;
        this.processing = false;
        this.processingEmitter.emit(this.processing);

        Swal.fire({
          icon: 'error',
          title: 'Error',
          text: errorResponse.error
        });
      }
    );
  }
}
